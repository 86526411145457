<template lang="">
    <div class="application-summary-section">
        <b-card class="st-section st-section__summary mt-6">
            <div class="st-section-header">
                <i v-if="isSigned || isDefault || isApplicationRegistered" :class="['fas fa-check-circle', {'text-success': isSigned || isApplicationRegistered}]"></i>
                <i v-else class="fas fa-check-circle text-dark-light"></i>
                <span>{{ $t('APPLICATION.SUMMARY.SIGN_TYPE_TITLE') }}</span>
            </div>
            <div class="st-section-body">
                <div class="font-weight-bold">{{ signTypeText }}</div>
                <div>{{ `${userFullName} - ${applicantUserPhone}` }}</div>
                <div v-if="isSignOnline && signDetails.package">
                    {{ signDetails.package.name }}
                </div>
            </div>
            <template #footer v-if="isSignOnline">
                <span class="text-success cursor-default">{{ $t('APPLICATION.SUMMARY.SIGN_TYPE_FOOTER') }}</span>
            </template>
        </b-card>
         <b-card class="st-section st-section__summary mt-6">
             <div class="st-section-header">
                <i v-if="isCompleted || isDefault" :class="['fas fa-check-circle', {'text-success': isCompleted}]"></i>
                <i v-else class="fas fa-check-circle text-dark-light"></i>
                <span>{{ $t('APPLICATION.SUMMARY.APPLICATION_DETAIL_TITLE') }}</span>
            </div>
            <div class="st-section-body">
                <div class="font-weight-bold">{{ $t('APPLICATION.SUMMARY.APPLICATION_OBJECTIVE') }}</div>
                <div>{{ applicationTypeName }}</div>

                <div class="font-weight-bold">{{ $t('APPLICATION.SUMMARY.APPLICANT') }}</div>
                <div>{{ userFullName }} - {{ userTypeName }}</div>
            </div>
            <template #footer v-if="requiredRealEstate">
                <span class="text-success cursor-pointer" @click="viewRealEstatesSummary">{{ realEstateCountText }}</span>
            </template>
        </b-card>
         <b-card class="st-section st-section__summary mt-6">
            <div class="st-section-header">
                <i v-if="isPaid || zeroTaxes" class="fas fa-check-circle text-success"></i>
                <i v-else class="fas fa-check-circle text-dark-light"></i>
                <span>{{ $t('APPLICATION.SUMMARY.PAYMENT_TYPE_TITLE') }}</span>
            </div>
            <div class="st-section-body" v-if="zeroTaxes">
                <div class="font-weight-bold">{{ $t('APPLICATION.SUMMARY.PAYMENT_DISCOUNT') }}</div>
                <div> {{ summaryPaymentDiscountText }} </div>
            </div>
            <div class="st-section-body" v-else-if="isOp">
                <div v-if="isApplicationRegistered" class="font-weight-bold">{{ $t('APPLICATION.SUMMARY.PAYMENT_OFFICE') }} - {{ $t('APPLICATION.SUMMARY.PAYMENT_TYPE_OP') }}</div>
                <div v-else class="font-weight-bold">{{ $t('APPLICATION.SUMMARY.PAYMENT_TYPE_OP') }}</div>
                <div> {{ summaryPaymentText }} </div>
                <div v-if="payment_summary.order_number && payment_summary.payment_date">
                    <div>{{ $t('APPLICATION.PAYMENT_ORDER.FIELDS.ORDER_NUMBER') }} {{ payment_summary.order_number }} </div>
                    <div>{{ $t('APPLICATION.PAYMENT_ORDER.FIELDS.PAYMENT_DATE') }} {{ payment_summary.payment_date }} </div>
                    <div class="form-group mt-6" v-if="file" >
                        <div class="st-files-container">
                            <div class="st-file-item">
                                <div class="text-truncate">{{ $t('APPLICATION.SUMMARY.FILE_NAME') }}</div>
                                <div class="st-file-item-actions">
                                    <i class="st-view-file fas fa-eye" @click="viewFile(file)"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="st-section-body" v-else>
                <div v-if="isApplicationRegistered" class="font-weight-bold">{{ $t('APPLICATION.SUMMARY.PAYMENT_OFFICE') }} - {{ paymentTypeText }}</div>
                <div class="font-weight-bold">{{ paymentTypeText }}</div>
                <div> {{ summaryPaymentText }} </div>
            </div>
        </b-card>

        <real-estate-summary-modal :items="formModels.real_estate_target" ref="realEstateSummary"></real-estate-summary-modal>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import RealEstateSummaryModal from './RealEstateSummaryModal.vue';

export default {
    name: 'ApplicationSummaryDetails',
    props: [ 'formModels', 'signDetails', 'paymentDetails', 'requiredRealEstate' ],
    components: {
        RealEstateSummaryModal
    },
    computed: {
        ...mapGetters({
           currentUser: 'auth/currentUser',
           record: 'applications/form/record',
           fileToken: "applications/form/fileToken",
           totalTaxes: "applications/form/totalTaxes",
        }),
        applicantUser() {
            return this.formModels?.applicant_form;
        },
        payment_summary() {
            return this.record?.payment_details;
        },
        file() {
            return this.record?.payment_details.file;
        },
        userFullName() {
            return `${this.applicantUser?.first_name} ${this.applicantUser?.last_name}`;
        },
        applicantUserPhone() {
            return this.applicantUser?.phone_number;
        },
        signTypeText() {
            if (this.isApplicationRegistered) {
                return this.$t('APPLICATION.SIGNATURE.OFFICE.SUMMARY_TEXT');
            }
            return this.signDetails.isOnline
            ? this.$t('APPLICATION.SIGNATURE.ONLINE.SUMMARY_TEXT')
            : this.$t('APPLICATION.SIGNATURE.OFFLINE.SUMMARY_TEXT');
        },
        applicationTypeName() {
            return this.formModels?.application_type_name || this.record?.application_type_name;
        },
        placementNo(){
            if (this.formModels?.real_estate_target) {
                return this.formModels.real_estate_target.length;
            }

            return 0;
        },
        userTypeName() {
            if (this.applicantUser?.user_type_name === 'legal_entity') {
                return this.$t("BENEFICIARY.BENEFICIARY_DETAILS.PERSON.LEGAL");
            }

            return this.$t("BENEFICIARY.BENEFICIARY_DETAILS.PERSON.INDIVIDUAL");
        },
        realEstateCountText() {
            return this.$tc('APPLICATION.SUMMARY.SELECTED_REAL_ESTATES_TEXT', this.placementNo, { count: this.placementNo});
        },
        isSignOnline() {
            return !!this.signDetails?.isOnline;
        },
        isSigned() {
            return !!this.record?.is_signed;
        },
        isDefault() {
            return !this.record;
        },
        isCompleted() {
            if (this.requiredRealEstate && !this.formModels?.real_estate_target?.length) {
                return false;
            }
            return this.formModels?.beneficiary_users?.length;
        },
        isPaid() {
            return this.record?.is_paid;
        },
        isOp() {
            return this.record?.payment_details?.payment_type === 'OP';
        },
        paymentTypeText(){
            if(this.paymentDetails?.payment_type === 'OP'){
                return this.$t('APPLICATION.SUMMARY.PAYMENT_TYPE_OP')
            } else if (this.paymentDetails?.payment_type === 'LINK') {
                return this.$t('APPLICATION.SUMMARY.PAYMENT_TYPE_LINK')
            } else {
                return this.$t('APPLICATION.SUMMARY.PAYMENT_TYPE')
            }
        },
        summaryPaymentText() {
            if (this.record?.is_paid && this.record.payment_details?.payment_type === 'OP') {
                return this.$t('APPLICATION.SUMMARY.PAYMENT_TYPE_HELP_OP_TEXT_SUCCESS');
            } else if(this.record?.is_paid){
                return this.$t('APPLICATION.SUMMARY.PAYMENT_TYPE_HELP_TEXT_SUCCESS');
            } else if(this.record?.payment_details?.payment_type === 'CARD' && this.record?.payment_status === 'failed'){
                return this.$t('APPLICATION.SUMMARY.PAYMENT_TYPE_HELP_TEXT_FAILED')
            } else if (this.paymentDetails?.payment_type === 'OP'){
                return this.$t('APPLICATION.SUMMARY.PAYMENT_TYPE_OP_HELP_TEXT')
            } else if (this.paymentDetails?.payment_type === 'LINK') {
                return this.$t('APPLICATION.SUMMARY.PAYMENT_TYPE_LINK_HELP_TEXT')  
            }else{
                return this.$t('APPLICATION.SUMMARY.PAYMENT_TYPE_HELP_TEXT')
            }
        },
        zeroTaxes() {
            return this.totalTaxes === 0;
        },
        isApplicationRegistered() {
            return !!this.record?.is_registry_application;
        },
        summaryPaymentDiscountText() {
            if (this.record?.is_paid) {
                return this.$t('APPLICATION.SUMMARY.PAYMENT_DISCOUNT_TEXT_SUCCESS');
            }
            return this.$t('APPLICATION.SUMMARY.PAYMENT_DISCOUNT_TEXT');
        },
    },
    methods: {
        viewRealEstatesSummary() {
            this.$refs.realEstateSummary.show();
        },
        viewFile(file_id) {
            const url = file_id
                ? `${window.VUE_APP_API_URL}/files/${file_id}?token=${this.fileToken}`
                : `${doc.url}`;
            window.open(url, "_blank");
        },
    },
};
</script>
